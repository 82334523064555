import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Paper,
  Divider,
  Stack,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  Close as CloseIcon,
  FileDownload as FileDownloadIcon,
  DeleteOutline as DeleteOutlineIcon,
  InsertDriveFileOutlined as FileIcon,
  CloudUpload as CloudUploadIcon,
  ErrorOutline as ErrorIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";

const getTheme = (isDark) =>
  createTheme({
    palette: {
      mode: isDark ? "dark" : "light",
      primary: {
        main: isDark ? "#2196f3" : "#1976d2",
        dark: isDark ? "#1976d2" : "#1565c0",
      },
      background: {
        default: isDark ? "#1a2027" : "#ffffff",
        paper: isDark ? "#1a2027" : "#ffffff",
      },
      text: {
        primary: isDark ? "#e2e8f0" : "#2c3e50",
        secondary: isDark ? "#94a3b8" : "#64748b",
      },
      error: {
        main: isDark ? "#ef4444" : "#dc2626",
        light: isDark ? "#450a0a" : "#fee2e2",
      },
      divider: isDark ? "#2d3748" : "#e2e8f0",
    },
  });

const EnhancedUploadModal = ({
  open,
  onClose,
  selectedFile,
  onFileSelect,
  onFileDelete,
  handleUploadSubmit,
  handleDownloadDemo,
  isUploading,
  uploadError,
  isDarkMode = false,
}) => {
  const theme = getTheme(isDarkMode);
  const fileInputRef = React.useRef(null);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            maxHeight: "85vh",
            margin: "16px",
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: theme.palette.primary.main,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "12px 16px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CloudUploadIcon />
            <Typography variant="h6" sx={{ fontSize: "1.1rem" }}>
              Upload Intake Data
            </Typography>
          </Box>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#fff",
              "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 5 }}>
          <Stack spacing={2}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, mb: 1, mt: 2 }}
              >
                1. Download Template
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", mb: 1.5 }}
              >
                Please use our CSV template to ensure your data is properly
                formatted.
              </Typography>
              <Button
                variant="outlined"
                startIcon={<FileDownloadIcon />}
                onClick={handleDownloadDemo}
                sx={{
                  borderRadius: "8px",
                  textTransform: "none",
                }}
              >
                Download CSV Template
              </Button>
            </Box>

            <Divider />

            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1.5 }}>
                2. Upload Your Data
              </Typography>

              <Paper
                variant="outlined"
                sx={{
                  border: "2px dashed",
                  borderColor: selectedFile ? "primary.main" : "divider",
                  borderRadius: "12px",
                  p: 2.5,
                  textAlign: "center",
                  bgcolor: selectedFile ? "action.hover" : "background.paper",
                  transition: "all 0.2s",
                  "&:hover": { borderColor: "primary.main" },
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={onFileSelect}
                  accept=".csv"
                  hidden
                />

                {!selectedFile ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <DescriptionIcon
                      sx={{ fontSize: 36, color: "primary.main" }}
                    />
                    <div>
                      <Typography sx={{ mb: 1 }}>
                        Drag and drop your CSV file here or
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => fileInputRef.current?.click()}
                        sx={{
                          borderRadius: "8px",
                          textTransform: "none",
                        }}
                      >
                        Browse Files
                      </Button>
                    </div>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      Supported format: CSV
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: "10px 12px",
                      bgcolor: "background.paper",
                      borderRadius: "8px",
                      border: "1px solid",
                      borderColor: "divider",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
                    >
                      <FileIcon sx={{ fontSize: 24, color: "primary.main" }} />
                      <div>
                        <Typography variant="subtitle2">
                          {selectedFile.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: "text.secondary" }}
                        >
                          {(selectedFile.size / 1024).toFixed(2)} KB
                        </Typography>
                      </div>
                    </Box>
                    <IconButton
                      onClick={onFileDelete}
                      sx={{
                        color: "text.secondary",
                        "&:hover": {
                          color: "error.main",
                          bgcolor: "error.light",
                        },
                      }}
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Paper>

              {uploadError && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 1.5,
                    p: "8px 12px",
                    bgcolor: "error.light",
                    borderRadius: "6px",
                    border: "1px solid",
                    borderColor: "error.main",
                  }}
                >
                  <ErrorIcon fontSize="small" color="error" />
                  <Typography variant="body2" color="error">
                    {uploadError}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              borderColor: "#f97316",
              color: "#f97316",
              "&:hover": {
                borderColor: "#ea580c",
                bgcolor: "rgba(249, 115, 22, 0.04)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUploadSubmit}
            disabled={!selectedFile || isUploading}
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              "&.Mui-disabled": {
                bgcolor: isDarkMode ? "#374151" : "#e2e8f0",
                color: isDarkMode ? "#6b7280" : "#94a3b8",
              },
            }}
          >
            {isUploading ? (
              <>
                <CircularProgress size={16} sx={{ mr: 1, color: "#fff" }} />
                Uploading...
              </>
            ) : (
              "Upload Data"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EnhancedUploadModal;
