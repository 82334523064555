import React from "react";
import { useEffect } from "react";
import { Box, Card, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { Icon } from "@iconify/react";
import { fShortenNumber } from "../../../utils/formatNumber";
import CountUp from "react-countup";
import DonutChart from "../../common/app/DonutChart";
import GenderCard from "../common/GenderCard";
import CohortInternship from "./CohortInternship";
import { useDispatch, useSelector } from "react-redux";
import { fetchTotalInternshipData } from "../../../store/Slices/dashboard/totalInternshipSlice";

const TotalInternship = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();
  const internshipStatus = useSelector(
    (state) => state.totalInternship.internshipStatus
  );

  let order = [
    "Applied",
    "Approved",
    "Internship Certificate Issued",
    "Rejected",
    "Certificate Verified",
    "Assessment Completed",
  ];

  // Create a new array to store the sorted internship status
  let sortedInternshipStatus = [];

  // Iterate through the order array and find the corresponding item in the internshipStatus array
  order.forEach((status) => {
    let item = internshipStatus?.find((obj) => obj.title === status);

    if (item) {
      sortedInternshipStatus.push(item);
    }
  });

  const genderStatus = useSelector(
    (state) => state.totalInternship.genderStatus
  );
  const isLoading = useSelector((state) => state.totalInternship.isLoading);

  useEffect(() => {
    if (internshipStatus?.length === 0) {
      dispatch(fetchTotalInternshipData());
    }
  }, [dispatch, internshipStatus]);

  const internship = internshipStatus?.map((item) => {
    let icon;
    if (item.title === "Applied") {
      icon = "carbon:task-complete";
    } else if (item.title === "Approved") {
      icon = "mdi:approve";
    } else if (item.title === "Internship Certificate Issued") {
      icon = "fluent-mdl2:completed-solid";
    } else if (item.title === "Assessment Completed") {
      icon = "fluent:tasks-app-28-filled";
    } else if (item.title === "Certificate Verified") {
      icon = "iconamoon:certificate-badge-fill";
    } else if (item.title === "Rejected") {
      icon = "fluent:text-change-reject-24-regular";
    }

    return {
      label: item.title,
      value: item.count,
      icon: icon,
    };
  });

  const isInternshipDataZero = internship && internship.length === 0;

  return (
    <Card elevation={5} sx={{ bgcolor: colors.blueAccent[800], p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h4"
          sx={{
            bgcolor: colors.blueAccent[300],
            py: 0.5,
            px: 4,
            borderRadius: 2,
            fontWeight: 500,
            color: colors.blueAccent[800],
          }}
        >
          Internships
        </Typography>
      </Box>
      <Grid container spacing={2} my={1} px={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={2}>
            {isLoading || isInternshipDataZero
              ? // Display loading skeleton cards or empty cards when data is 0
                Array.from({ length: 4 }).map((_, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Card
                      elevation={4}
                      sx={{
                        bgcolor: colors.blueAccent[800],
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {isInternshipDataZero && !isLoading ? (
                        // Display nothing inside the card
                        <Box
                          sx={{
                            p: 1.1,
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={40}
                            height={40}
                            sx={{
                              borderRadius: 1,
                              bgcolor: colors.redAccent[900],
                            }}
                            animation="wave"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Skeleton
                              variant="text"
                              width={40}
                              height={25}
                              animation="wave"
                              sx={{ bgcolor: colors.redAccent[900] }}
                            />
                            <Skeleton
                              variant="text"
                              width={60}
                              height={15}
                              animation="wave"
                              sx={{ bgcolor: colors.redAccent[900] }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        // Display a loading skeleton inside the card
                        <>
                          <Box
                            sx={{
                              p: 1.1,
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={40}
                              height={40}
                              sx={{ borderRadius: 1 }}
                              animation="wave"
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Skeleton
                                variant="text"
                                width={40}
                                height={25}
                                animation="wave"
                              />
                              <Skeleton
                                variant="text"
                                width={60}
                                height={15}
                                animation="wave"
                              />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Card>
                  </Grid>
                ))
              : // Display actual content once data is loaded
                internship?.map((item, key) => (
                  <Grid item xs={12} sm={12} md={6} key={key}>
                    <Card elevation={0} className="card-animate">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={1}
                        px={1.2}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          borderRadius={1}
                          p={0.5}
                          bgcolor={colors.blueAccent[300]}
                        >
                          <Icon
                            icon={item.icon}
                            color={colors.blueAccent[800]}
                            height={32}
                          />
                        </Box>
                        <Box
                          sx={{
                            mr: 0.5,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            variant="h4"
                            fontWeight="700"
                            component="span"
                            sx={{
                              textShadow: `2px 2px 4px ${colors.greenAccent[900]}`,
                            }}
                            color={colors.blueAccent[100]}
                          >
                            <span className="counter-value" data-target="0">
                              <span>
                                {" "}
                                {item.value > 99999 ? (
                                  <>
                                    <CountUp
                                      start={0}
                                      end={parseFloat(
                                        fShortenNumber(item.value)
                                      )}
                                      duration={3}
                                    />
                                    {fShortenNumber(item.value).slice(-1)}
                                  </>
                                ) : (
                                  <CountUp
                                    start={0}
                                    end={parseFloat(item.value)}
                                    duration={3}
                                  />
                                )}
                              </span>
                            </span>
                          </Typography>
                          <Typography
                            variant="body2"
                            color={colors.blueAccent[300]}
                            sx={{
                              fontWeight: 700,
                            }}
                            noWrap
                          >
                            {item.label}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <DonutChart data={sortedInternshipStatus} isLoading={isLoading} />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <GenderCard height={132} data={genderStatus} isLoading={isLoading} />
        </Grid>

        <Grid item xs={12}>
          <CohortInternship />
        </Grid>
      </Grid>
    </Card>
  );
};

export default TotalInternship;
